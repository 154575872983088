@import-normalize;
@import '~flexboxgrid/css/index';

$fa-font-path: '../webfonts';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

@tailwind base;
@tailwind utilities;

@import '~aos/dist/aos.css';
